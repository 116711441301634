define("discourse/plugins/discourse-cakeday/discourse/controllers/cakeday-anniversaries-all", ["exports", "@ember/controller", "discourse-common/utils/decorators"], function (_exports, _controller, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    queryParams: ["month"],
    month: moment().month() + 1,
    months() {
      return moment.months().map((month, index) => {
        return {
          name: month,
          value: index + 1
        };
      });
    },
    actions: {
      loadMore() {
        this.get("model").loadMore();
      }
    }
  }, [["method", "months", [_decorators.default]]]));
});