define("discourse/plugins/discourse-cakeday/discourse/controllers/cakeday", ["exports", "@ember/controller", "@ember/object/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    cakedayEnabled: (0, _computed.alias)("siteSettings.cakeday_enabled"),
    birthdayEnabled: (0, _computed.alias)("siteSettings.cakeday_birthday_enabled")
  });
});